// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

// All other imports
import TextField from 'components/core/TextField';
import { useServices } from 'services';
import SignUpData from 'types/SignUpData';
import SignUpSuccess from 'components/auth/SignUpSuccess';
import SignUpError from 'components/auth/SignUpError';
import Form from 'components/core/Form';
import AffiliationAutocomplete from 'components/core/AffiliationAutocomplete';

export interface SignUpComponentProps {
  className?: string;
}

const formSchema: yup.ObjectSchema<SignUpData> = yup.object({
  email: yup.string().required().email().label('Email'),
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters.')
    .matches(
      /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w]|[&.-]).*$/,
      'Password must have uppercase, lowercase, symbol, and numeric characters.'
    )
    .label('Password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required()
    .label('Confirm Password'),
  affiliation: yup.string().required().label('Affiliation'),
});

const defaultFormValues: SignUpData = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  affiliation: '',
};

function SignUpComponent(
  props: SignUpComponentProps
): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();

  return (
    <Form<SignUpData>
      className={className}
      schema={formSchema}
      defaultValues={defaultFormValues}
      onSubmit={(data) => authService.signUp(data)}
      getErrorMessage={(rejectedVal) => {
        return <SignUpError error={rejectedVal} />;
      }}
      getSuccessMessage={() => <SignUpSuccess />}
      submitText="Sign Up"
    >
      {(formState) => (
        <>
          <TextField
            formState={formState}
            fieldName="email"
            textFieldProps={{ type: 'email' }}
          />
          <TextField formState={formState} fieldName="firstName" />
          <TextField formState={formState} fieldName="lastName" />
          <AffiliationAutocomplete
            formState={formState}
            fieldName="affiliation"
          />
          <TextField
            formState={formState}
            fieldName="password"
            textFieldProps={{ type: 'password' }}
          />
          <TextField
            formState={formState}
            fieldName="confirmPassword"
            textFieldProps={{ type: 'password' }}
          />
        </>
      )}
    </Form>
  );
}

export default observer(SignUpComponent);
