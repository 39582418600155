// NPM packages
import React, { useEffect } from 'react';
import { Typography, Alert, AlertTitle } from '@mui/material';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// All other imports
import PageContent from 'components/core/PageContent';
import { ConfirmationService, useServices } from 'services';
import H1 from 'components/core/H1';
import ConfirmationError from 'types/errors/ConfirmationError';

const Root = styled(PageContent)({});

export interface DisclaimerProps {
  className?: string;
}

const OperatorsStatement = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '2rem 0',
});

function Disclaimer(props: DisclaimerProps): React.ReactElement | null {
  const { className } = props;
  const { confirmationService } = useServices();
  const [searchParams] = useSearchParams();
  const urlToContinue = searchParams.get('urlToContinue');
  const urlToGoBack = searchParams.get('urlToGoBack');

  useEffect(() => {
    showDisclaimer({ confirmationService, urlToContinue, urlToGoBack });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let body: React.ReactNode;
  if (!urlToContinue || !urlToGoBack) {
    body = (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Malformed URL
      </Alert>
    );
  } else {
    body = <Typography>Awaiting acknowledgment...</Typography>;
  }
  return <Root className={className}>{body}</Root>;
}

export default observer(Disclaimer);

async function showDisclaimer({
  confirmationService,
  urlToContinue,
  urlToGoBack,
}: {
  confirmationService: ConfirmationService;
  urlToContinue?: string | null;
  urlToGoBack?: string | null;
}): Promise<void> {
  if (!urlToContinue || !urlToGoBack) {
    return;
  }
  const prompt = (
    <div>
      <H1>Disclaimer</H1>
      <Typography>
        This material was prepared as an account of work sponsored by an agency
        of the United States Government. Neither the United States Government
        nor the United States Department of Energy, nor Battelle, nor any of
        their employees, nor any jurisdiction or organization that has
        cooperated in the development of these materials, makes any warranty,
        express or implied, or assumes any legal liability or responsibility for
        the accuracy, completeness, or usefulness or any information, apparatus,
        product, software, or process disclosed, or represents that its use
        would not infringe privately owned rights.
      </Typography>
      <Typography>
        Reference herein to any specific commercial product, process, or service
        by trade name, trademark, manufacturer, or otherwise does not
        necessarily constitute or imply its endorsement, recommendation, or
        favoring by the United States Government or any agency thereof, or
        Battelle Memorial Institute. The views and opinions of authors expressed
        herein do not necessarily state or reflect those of the United States
        Government or any agency thereof.
      </Typography>
      <OperatorsStatement>
        <div>PACIFIC NORTHWEST NATIONAL LABORATORY</div>
        <div>operated by</div>
        <div>BATTELLE</div>
        <div>for the</div>
        <div>UNITED STATES DEPARTMENT OF ENERGY</div>
        <div>under Contract DE-AC05-76RL01830</div>
      </OperatorsStatement>
    </div>
  );
  try {
    await confirmationService.promptUser({
      prompt,
      yesText: 'Acknowledge',
      noText: 'Go Back',
      closeOnAnswer: false,
    });
    window.location.href = urlToContinue;
  } catch (error: unknown) {
    if (error instanceof ConfirmationError) {
      window.location.href = urlToGoBack;
    }
    throw error;
  }
}
