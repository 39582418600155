import { computed, makeObservable, observable, runInAction } from 'mobx';
import ConfirmationError from 'types/errors/ConfirmationError';
import {
  EnvService,
  GlobalErrorService,
  RdmApiService,
  ConfirmationService,
} from 'services';
import UserJson from './UserJson';
import UserRole from './UserRole';
import { computedFn } from 'mobx-utils';

export default class User {
  envService: EnvService;
  rdmApiService: RdmApiService;
  globalErrorService: GlobalErrorService;
  confirmationService: ConfirmationService;
  loading: boolean = false;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email: string;
  affiliation: string;
  username: string;
  groups: string[];
  get isGlobalAdmin(): boolean {
    const siteAdminGroup = this.envService.getValue('SITE_ADMIN_GROUP');
    return Boolean(
      this.groups.find((group: string) => group === siteAdminGroup)
    );
  }

  constructor({
    userData,
    envService,
    rdmApiService,
    globalErrorService,
    confirmationService,
  }: {
    userData: UserJson;
    envService: EnvService;
    rdmApiService: RdmApiService;
    globalErrorService: GlobalErrorService;
    confirmationService: ConfirmationService;
  }) {
    this.envService = envService;
    this.rdmApiService = rdmApiService;
    this.globalErrorService = globalErrorService;
    this.confirmationService = confirmationService;

    const { firstName, lastName, email, affiliation, username, groups } =
      userData;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.affiliation = affiliation;
    this.username = username;
    this.groups = groups || [];

    makeObservable(this, {
      loading: observable,
      firstName: observable,
      lastName: observable,
      email: observable,
      affiliation: observable,
      username: observable,
      groups: observable,
      isGlobalAdmin: computed,
    });
  }

  private getAdminGroup = (projectSlug: string): string => {
    return `${projectSlug}_admins`;
  };

  private getCuratorGroup = (projectSlug: string): string => {
    return `${projectSlug}_curators`;
  };

  private getMemberGroup = (projectSlug: string): string => {
    return projectSlug;
  };

  private getPendingGroup = (projectSlug: string): string => {
    return `${projectSlug}_pendings`;
  };

  private getGroup = ({
    projectSlug,
    role,
  }: {
    projectSlug: string;
    role: UserRole;
  }): string => {
    return {
      [UserRole.ADMIN]: this.getAdminGroup(projectSlug),
      [UserRole.CURATOR]: this.getCuratorGroup(projectSlug),
      [UserRole.MEMBER]: this.getMemberGroup(projectSlug),
      [UserRole.PENDING]: this.getPendingGroup(projectSlug),
    }[role];
  };

  private getProjectGroups = (projectSlug: string): string[] => {
    return [
      this.getAdminGroup(projectSlug),
      this.getCuratorGroup(projectSlug),
      this.getMemberGroup(projectSlug),
      this.getPendingGroup(projectSlug),
    ];
  };

  private getProjectGroupsForUser = (projectSlug: string): string[] => {
    const groupSet = new Set(this.groups);
    return this.getProjectGroups(projectSlug).filter((group) =>
      groupSet.has(group)
    );
  };

  addRole = async ({
    projectSlug,
    role,
  }: {
    projectSlug: string;
    role: UserRole;
  }): Promise<void> => {
    const newGroup = this.getGroup({ projectSlug, role });
    if (this.groups.includes(newGroup)) {
      return;
    }
    runInAction(() => {
      this.loading = true;
    });
    try {
      if (role === UserRole.MEMBER) {
        await this.rdmApiService.axios.put(
          `/projects/members/${projectSlug}/${this.email}`,
          {
            groups: [newGroup],
          }
        );
        runInAction(() => {
          this.groups = [
            ...this.groups.filter(
              (group) => group !== this.getPendingGroup(projectSlug)
            ),
            newGroup,
          ];
        });
      } else {
        if (role === UserRole.PENDING) {
          await this.rdmApiService.axios.post(
            `/projects/requests/${projectSlug}`
          );
        } else {
          const newProjectGroups = [
            ...this.getProjectGroupsForUser(projectSlug),
            newGroup,
          ];
          await this.rdmApiService.axios.post(
            `/projects/members/${projectSlug}/${this.email}`,
            {
              groups: newProjectGroups,
            }
          );
        }
        runInAction(() => {
          this.groups = [...this.groups, newGroup];
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.globalErrorService.showError({
          message: `Error adding role: ${error.message}`,
        });
      }
      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  removeRole = async ({
    projectSlug,
    role,
  }: {
    projectSlug: string;
    role: UserRole;
  }): Promise<void> => {
    const groupToRemove = this.getGroup({ projectSlug, role });
    runInAction(() => {
      this.loading = true;
    });

    try {
      const projectGroups = this.getProjectGroups(projectSlug);
      if (role === UserRole.MEMBER) {
        if (!this.isPending(projectSlug) && !this.isMember(projectSlug)) {
          throw new Error("Can't remove role since not a member");
        }
        let confirmationPrompt = '';
        if (this.isPending(projectSlug)) {
          confirmationPrompt = `Are you sure you want to reject ${this.email}?`;
        } else if (this.isMember(projectSlug)) {
          confirmationPrompt = `Are you sure you want to remove ${this.email}?`;
        }
        await this.confirmationService.promptUser({
          prompt: confirmationPrompt,
        });
        await this.rdmApiService.axios.delete(
          `/projects/members/${projectSlug}/${this.email}`
        );
        runInAction(() => {
          this.groups = this.groups.filter(
            (currentGroup) => !projectGroups.includes(currentGroup)
          );
        });
      } else {
        if (!this.groups.includes(groupToRemove)) {
          throw new Error(
            `User doesn't have role ${role} for project ${projectSlug}`
          );
        }
        await this.confirmationService.promptUser({
          prompt: `Remove ${this.email} from the ${role} role?`,
        });
        const newProjectGroups = this.getProjectGroupsForUser(
          projectSlug
        ).filter((currentGroup) => currentGroup !== groupToRemove);
        await this.rdmApiService.axios.post(
          `/projects/members/${projectSlug}/${this.email}`,
          {
            groups: newProjectGroups,
          }
        );
        runInAction(() => {
          this.groups = this.groups.filter(
            (currentGroup) => currentGroup !== groupToRemove
          );
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error && !(error instanceof ConfirmationError)) {
        this.globalErrorService.showError({
          message: `Error removing role: ${error.message}`,
        });
      }
      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  isMember = computedFn((projectSlug: string): boolean => {
    return (
      this.isGlobalAdmin ||
      this.groups.includes(this.getMemberGroup(projectSlug))
    );
  });

  isCurator = computedFn((projectSlug: string): boolean => {
    return (
      this.isGlobalAdmin ||
      this.groups.includes(this.getCuratorGroup(projectSlug))
    );
  });

  isAdmin = computedFn((projectSlug: string): boolean => {
    return (
      this.isGlobalAdmin ||
      this.groups.includes(this.getAdminGroup(projectSlug))
    );
  });

  isPending = computedFn((projectSlug: string): boolean => {
    return this.groups.includes(this.getPendingGroup(projectSlug));
  });
}
