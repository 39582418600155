// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import Link from 'components/core/Link';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});

export interface SignUpSuccessProps {
  className?: string;
}

function SignUpSuccess(props: SignUpSuccessProps): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <Typography>
        You need to verify your email before logging in. Didn't get a
        verification email? Try{' '}
        <Link to={`/resend-verification`}>resending it</Link>.
      </Typography>
    </Root>
  );
}

export default observer(SignUpSuccess);
